<template>
    <div class="settings">
      <h1>Settings</h1>
      <p>Customize your iMonster experience here.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Settings'
  }
  </script>
  
  <style scoped>
  .settings {
    padding: 20px;
  }
  </style>